body {
  margin: 0;
  font-family: 'Battambang', 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
}

#root {
  min-height: 100%;
  min-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  display: inline-block;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
  margin: 16px 0;
}

.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

.site-form-item-icon {
  color: rgba(0, 0, 0, 0.25);
}

.description-wrapper {
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  line-height: 1em;
}

[data-theme='compact'] .description-wrapper {
  font-size: 12px;
  line-height: 1.66667;
}

.ant-drawer-body p.site-description-item-profile-p {
  display: block;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  line-height: 1.5715;
  font-weight: bold;
}

[data-theme='compact'] .ant-drawer-body p.site-description-item-profile-p {
  font-size: 14px;
  line-height: 1.66667;
}

.item-headline {
  display: block;
  color: #333;
  font-size: 13px;
  margin-bottom: 8px;
  line-height: 0.85em;
}

.item-label {
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
  line-height: 1.5715;
}

[data-theme='dark'] .site-description-item-profile-p {
  color: rgba(255, 255, 255, 0.85);
}
[data-theme='dark'] .description-wrapper {
  color: rgba(255, 255, 255, 0.65);
}

.doc-row-normal {
  background-color: #bae7ff;
}

.doc-row-signature {
  background-color: #d9f7be;
}

.doc-row-urgent {
  background-color: #ffccc7;
}

.preview-page-wrapper {
  width: 100%;
  height: 100vh;
}

.popover-sign {
  max-width: 360px;
  width: 100%;
}

.btn-action {
  color: black !important;
  display: block;
  text-align: center;
}

.btn-action > span {
  vertical-align: middle;
}

.circle-badge {
  width: 4px;
  height: 4px;
  background-color: #ababab;
  border-radius: 50%;
}

.bg-light-red {
  background-color: #ffccc7;
}

.bg-light-yellow {
  background-color: lightyellow;
}

.bg-light-blue {
  background-color: #bae7ff;
}

.bg-light-orange {
  background-color: lightsalmon;
}

.ant-table-custom .ant-table-thead .ant-table-cell {
  background-color: #002c8c;
  color: white;
}

.ant-table-custom .ant-table-thead .ant-table-cell:hover,
.ant-table-custom .ant-table-thead .ant-table-cell:focus {
  background-color: #003eb3;
}

.ant-table-custom .ant-table-column-sorter.ant-table-column-sorter-full,
.ant-table-custom .ant-table-column-sorters:hover .ant-table-column-sorter {
  color: white;
}

.ant-table-custom .ant-table-thead th.ant-table-column-has-sorters:hover,
.ant-table-custom.ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
  background-color: #003eb3;
}
